import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

const OddsComparisonsData = [
  {
    id: 1,
    feature: "Number of Bookmakers",
    soc: {
      name: "Almost 100 bookmakers",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "Around 20",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
    betwatch: {
      name: "Over 50",
      BGColor: "#ffcd5426",
      Color: "#FFCD54",
    },
  },
  {
    id: 2,
    feature: "Tab - 3 Totes",
    soc: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    betwatch: {
      name: "No",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
  },
  {
    id: 3,
    feature: "Customisable Layouts",
    soc: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    betwatch: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
  },
  {
    id: 4,
    feature: "New Monthly Features",
    soc: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "No",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
    betwatch: {
      name: "No",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
  },
  {
    id: 5,
    feature: "Free trial period",
    soc: {
      name: "14 days",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "None",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
    betwatch: {
      name: "7 days",
      BGColor: "#ffcd5426",
      Color: "#FFCD54",
    },
  },
  {
    id: 6,
    feature: "Mobile app",
    soc: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "No",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
    betwatch: {
      name: "No",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
  },
  {
    id: 7,
    feature: "Mobile Optimised",
    soc: {
      name: "Yes",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "No",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
    betwatch: {
      name: "No",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
  },
  {
    id: 8,
    feature: "Price",
    soc: {
      name: "Less than $27 per month",
      BGColor: "#78c2a726",
      Color: "#78C2A7",
    },
    dynamicOdds: {
      name: "$35 per month",
      BGColor: "#ff000026",
      Color: "#FF0000",
    },
    betwatch: {
      name: "$29 per month",
      BGColor: "#ffcd5426",
      Color: "#FFCD54",
    },
  },
];

const OddsComparisons = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/subscription-payment");
  };
  return (
    <>
      <Box className="odds-comparsion-wrap-section">
        <Box className="odds-comparsion-container">
          <Box className="odds-comparsion-wrap">
            <Typography className="soc-signup-section-header">
              <span className="highlight-text">Odds</span> Comparisons
            </Typography>
            <Typography className="soc-signup-header-details">
              Compare how the Smart Odds Comparison stacks up against other
              popular odds comparison sites.
            </Typography>
            <TableContainer className="signup-odds-comparsion-container">
              <Table className="signup-odds-comparsion-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Features</TableCell>
                    <TableCell>Smart odds Comparison (SOC)</TableCell>
                    <TableCell>Dynamic Odds</TableCell>
                    <TableCell>Betwatch</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {OddsComparisonsData?.map((item, index) => {
                    return (
                      <>
                        <TableRow key={index} className="even-odds-rows">
                          <TableCell>{item.feature}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              className="odds-comparsion-btn cursor-default"
                              style={{
                                backgroundColor: item?.soc?.BGColor,
                                color: item?.soc?.Color,
                              }}
                            >
                              {item.soc?.name}
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              className="odds-comparsion-btn cursor-default"
                              style={{
                                backgroundColor: item?.dynamicOdds?.BGColor,
                                color: item?.dynamicOdds?.Color,
                              }}
                            >
                              {item.dynamicOdds?.name}
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              className="odds-comparsion-btn cursor-default"
                              style={{
                                backgroundColor: item?.betwatch?.BGColor,
                                color: item?.betwatch?.Color,
                              }}
                            >
                              {item.betwatch?.name}
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="odds-comparsion-btn-wrap">
              <Button
                variant="contained"
                className="soc-sign-up-button"
                onClick={() => handleNavigate()}
              >
                Start your free trial now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OddsComparisons;
