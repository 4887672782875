import React from "react";
import { Box } from "@mui/material";
import SOCSignupHeader from "./socSignupHeader";
import SOCFeatures from "./socFeatures";
import SOCHowItworks from "./socHowItworks";
import OddsComparisons from "./oddsComparisons";
import SOCBronze from "./socBronze";
import SOCBenefits from "./socBenefits";
import SmartBAppNow from "./smartBAppNow";
import SOCTestimonials from "./socTestimonials";
import OurPartner from "../ourPartners";
import "./socSignupPageV2.scss";

const SOCSignupPageV2 = () => {
  return (
    <>
      <Box className="soc-signup-section-wrap">
        <SOCSignupHeader />
        <SOCFeatures />
        <SOCHowItworks />
        <OddsComparisons />
        <SOCBronze />
        <SOCBenefits />
        <SmartBAppNow />
        <SOCTestimonials />
        <OurPartner />
      </Box>
    </>
  );
};

export default SOCSignupPageV2;
