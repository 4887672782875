import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";

const SOCBronze = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/subscription-payment");
  };
  return (
    <>
      <Box className="soc-bronze-wrap-section">
        <Box className="soc-bronze-container">
          <Box className="soc-bronze-wrap">
            <Typography className="soc-signup-section-header">
              Start your free trial of{" "}
              <span className="highlight-text">SOC Bronze</span>
            </Typography>
            <Typography className="soc-signup-header-details">
              Gain access to the Smart Odds Comparison Bronze Package and get
              early access to the new features in our Silver and Gold packages.
            </Typography>
            <Box className="bronze-prize-section">
              <Button variant="contained" className="prize-btn cursor-default">
                $26.99<span>/month</span>
              </Button>
              <span className="free-trial">14-day free trial</span>
            </Box>
            <Box className="bronze-prize-btn-wrap">
              <Button
                variant="contained"
                className="soc-sign-up-button"
                onClick={() => handleNavigate()}
              >
                I want Better odds!
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SOCBronze;
