import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import banner from "../../../assets/images/banner/smart-b-banner-primary.webp";
import AdvertisingBannner from "../../component/advertisingBanner";
import "./policies.scss";
import OurPartner from "../../component/ourPartners";
import { IntlContext } from "src/App";
import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import { Helmet } from "react-helmet";
import { Config } from "src/helpers/context";

const SubscriptionTermsAndConditions = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      <Helmet>
        <title>
          SmartB | Check Our Detailed Subscription Terms & Conditions
        </title>
        <meta
          name="description"
          content="We hope you'll enjoy your visit to SmartB. Here are a few things you should know about the content and services available to you on or across our site"
        />
      </Helmet>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        {screenWidth > 479 ? "" : <AdvertisingBannner src={banner} />}
        <Box className="sport-wrap">
          <Box className="terms-wrap">
            <Typography variant="h1">
              <span className="title-orange">
                {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.TITLE}
              </span>
            </Typography>

            <Typography variant="h5" className="mb-10 mt-15">
              {
                localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                  ?.SUBSCRIPTION_TERMS_AND_CONDITIONS_SUBSCRIPTION
              }
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.TERMS_AND_CONDITIONS_SMARTB_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.TERMS_AND_CONDITIONS_SMARTB_LIST_2
                }
                <a href={"/subscription-payment"}>
                  <b className="gambling-link">smartb.com.au/subscription</b>
                </a>
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.TERMS_AND_CONDITIONS_SMARTB_LIST_3
                }
              </li>
              <li>
                <Typography>
                  {
                    localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                      ?.TERMS_AND_CONDITIONS_SMARTB_LIST_4
                  }
                </Typography>
                <Typography>
                  {
                    localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                      ?.TERMS_AND_CONDITIONS_SMARTB_LIST_5
                  }
                </Typography>
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.MEMBERSHIP}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_MEMBERSHIP_1
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_MEMBERSHIP_2
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_MEMBERSHIP_3
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_MEMBERSHIP_4
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_MEMBERSHIP_5
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.USE_OF_SMARTB_MEMBERSHIP_6
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.NO_REFUNDS}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.NO_REFUNDS_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.NO_REFUNDS_LIST_2
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.NO_REFUNDS_LIST_3
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.CANCELLATION}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.CANCELLATION_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.CANCELLATION_LIST_2
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.COMMERCIAL_USE}
            </Typography>
            <ul>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.COMMERCIAL_USE_LIST_1
                }
              </li>
            </ul>

            <Typography variant="h5" className="mb-10">
              {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.CHANGES_TO_TERMS}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.CHANGES_TO_TERMS_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.CHANGES_TO_TERMS_LIST_2
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {
                localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                  ?.LIMITATION_OF_LIABILITY
              }
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.LIMITATION_OF_LIABILITY_LIST_1
                }
              </li>
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.LIMITATION_OF_LIABILITY_LIST_2
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.GOVERNING_LAW}
            </Typography>
            <ul className="terms-ul">
              <li>
                {
                  localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                    ?.GOVERNING_LAW_LIST_1
                }
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {
                localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                  ?.INTELLECTUAL_PROPERTY
              }
            </Typography>
            <ul className="terms-ul">
              <li>
                <Typography style={{ marginBottom: "5px" }}>
                  {
                    localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                      ?.INTELLECTUAL_PROPERTY_LIST_1_HEADING
                  }
                </Typography>
                <Typography>
                  {
                    localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                      ?.INTELLECTUAL_PROPERTY_LIST_1_PARA
                  }
                  <br />
                  <a
                    href={
                      Config?.release == "IN"
                        ? "mailto:info@smartb.co.in"
                        : "mailto:info@smartb.com.au"
                    }
                  >
                    <b className="gambling-link">
                      {Config?.release == "IN"
                        ? "info@smartb.co.in"
                        : "info@smartb.com.au"}
                    </b>
                  </a>
                </Typography>
                <Typography>
                  {
                    localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                      ?.INTELLECTUAL_PROPERTY_LIST_2_PARA
                  }
                </Typography>
              </li>
            </ul>
            <Typography variant="h5" className="mb-10">
              {localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS?.CONTACT_US}
            </Typography>
            <ul className="terms-ul">
              <li>
                <Typography>
                  {
                    localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                      ?.CONTACT_US_LIST_1_PARA
                  }
                  <br />
                  <a
                    href={
                      Config?.release == "IN"
                        ? "mailto:info@smartb.co.in"
                        : "mailto:info@smartb.com.au"
                    }
                  >
                    <b className="gambling-link">
                      {Config?.release == "IN"
                        ? "info@smartb.co.in"
                        : "info@smartb.com.au"}
                    </b>
                  </a>
                </Typography>
                <Typography>
                  {
                    localesData?.SUBSCRIPTION_TERMS_AND_CONDITIONS
                      ?.CONTACT_US_LIST_2_PARA
                  }
                </Typography>
              </li>
            </ul>

            <Typography variant="body2" className="mb-0">
              <b>SmartB</b>
            </Typography>
            <Typography variant="body2" className="mb-0">
              <a
                href={
                  Config?.release == "IN"
                    ? "mailto:info@smartb.co.in"
                    : "mailto:info@smartb.com.au"
                }
              >
                <b>
                  {Config?.release == "IN"
                    ? "info@smartb.co.in"
                    : "info@smartb.com.au"}
                </b>
              </a>
            </Typography>
            <Typography variant="body2" className="mb-0 pb-46">
              <a href="tel:1300065591">
                <b>1300 065 591 </b>
              </a>
            </Typography>
          </Box>
          {/* <Box className="our-partner">
            <Typography variant="h1">OUR PARTNERS</Typography>
            <h5>Coming Soon</h5>
          </Box> */}
        </Box>
        {screenWidth > 479 ? "" : <OurPartner />}
      </Box>
    </>
  );
};

export default SubscriptionTermsAndConditions;
