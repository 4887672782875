import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import RaceAlerts from "../../../assets/images/socSignupPage/raceAlerts.png";
import PriceWatchlist from "../../../assets/images/socSignupPage/priceWatchlist.png";
import GridSettings from "../../../assets/images/socSignupPage/gridSettings.png";
import MeetingRacesFilters from "../../../assets/images/socSignupPage/meetingRacesFilters.png";
import BestOddsColumn from "../../../assets/images/socSignupPage/bestOddsColumn.png";
import Blackbook from "../../../assets/images/socSignupPage/blackbook.png";
import MobileOptimised from "../../../assets/images/socSignupPage/mobileOptimised.png";
import MobileApp from "../../../assets/images/socSignupPage/mobileApp.png";

const socBenefitsData = [
  {
    id: 1,
    icon: RaceAlerts,
    name: "Race alerts",
    details:
      "Set race alerts for when a race is starting so you can follow the odds and results.",
  },
  {
    id: 2,
    icon: PriceWatchlist,
    name: "Price watchlist",
    details:
      "Set price watchlists and be alerted when a runner hits a certain price.",
  },
  {
    id: 3,
    icon: GridSettings,
    name: "Grid Settings",
    details:
      "Customise your layouts to see the bookmakers you want to see, and the odds you most want to see.",
  },
  {
    id: 4,
    icon: MeetingRacesFilters,
    name: "Meeting & Races Filters",
    details: "Set up your filters so you see the races you are interested in.",
  },
  {
    id: 5,
    icon: BestOddsColumn,
    name: "Best odds column",
    details: `The "Best Odds" column shows the best odds from different bookmakers, and you can select which ones to view.`,
  },
  {
    id: 6,
    icon: Blackbook,
    name: "Blackbook",
    details:
      "Use the SmartB Blackbook in conjunction with the SOC to improve your odds.",
  },
  {
    id: 7,
    icon: MobileOptimised,
    name: "Mobile optimised",
    details:
      "Access the website on your desktop, tablet, or mobile while you're on the go.",
  },
  {
    id: 8,
    icon: MobileApp,
    name: "Mobile app",
    details:
      "The SmartB Mobile app includes the Smart Odds Comparison in it's entirety.",
  },
];

const SOCBenefits = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/subscription-payment");
  };
  return (
    <>
      <Box className="soc-benefits-wrap-section">
        <Box className="soc-benefits-container">
          <Box className="soc-benefits-wrap">
            <Typography className="soc-signup-section-header color-white">
              SOC
              <span className="highlight-text"> Benefits</span>
            </Typography>
            <Typography className="soc-signup-header-details color-white">
              There's so many benefits to the SOC, and we're always adding more.
              Our users gain early access to new features.
            </Typography>
            <Box className="soc-benefits-grid-details">
              {socBenefitsData?.map((item, index) => (
                <Box key={item.id} className="soc-benefits-grid-item">
                  <Box className="soc-benefits-icon-wrap">
                    <img src={item.icon} alt={item.name} />
                  </Box>
                  <Box className="soc-benefits-details">
                    <Typography className="name">{item.name}</Typography>
                    <Typography className="details">{item.details}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className="much-more-section">
              <Typography className="much-more-header">
                and much <span className="highlight-text">more...</span>
              </Typography>
              <Button
                variant="contained"
                className="soc-sign-up-button"
                onClick={() => handleNavigate()}
              >
                Start your free trial now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SOCBenefits;
